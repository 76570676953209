import { createContext, useContext, useState, useEffect } from 'react';
import api from '../api';
import getDefaultUserData from './defaultData';
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // check localStorage for user data and set it as the initial state or use the default data
  const [userData, setUserData] = useState(() => {
    const storedUserData = localStorage.getItem('userData');
    return storedUserData ? JSON.parse(storedUserData) : {
      loggedIn: false,
      userId: null,
      user: {
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        subscriptionTier: '',
        current_month_requests: '',
      },
      familyMembers: [],
      userFavorites: [],
      sharedFamilyMembers: [],
      gifts: [],
      userInfo: {
        id: '',
        user_id: '',
        profilepicturepath: '',
        birthday: '',
        phoneNum: '',
        facebook: '',
        instagram: '',
        twitter: '',
        snapchat: '',
        tiktok: '',
        biography: '',
        selectedInterests: '',
        hobbiesAdditionalInfo: '',
        newHobbie: '',
        entertainmentAdditionalInfo: '',
        newEntertainment: '',
        foodDrinkAdditionalInfo: '',
        newFoodDrink: '',
        beautyWellnessAdditionalInfo: '',
        newBeautyWellness: '',
        petsAdditionalInfo: '',
        newPets: '',
        travelingAdditionalInfo: '',
        newTraveling: '',
        valuesTraitsAdditionalInfo: '',
        newValuesTraits: '',
        sportsAdditionalInfo: '',
        teamOrPlayer: ''
      },
    };
  });
  useEffect (() => {
    fetchUserData();
  }, []);
  const updateUser = (newUserData) => {
    setUserData((prevUserData) => {
      const updatedData = {
        ...prevUserData,
        ...newUserData,
      };
      localStorage.setItem('userData', JSON.stringify({ data: updatedData }));
      return updatedData;
    });
  };

  const fetchUserData = async () => {
    try {
      const response = await api.get('/user', { withCredentials: true });
      if (response.data && response.data.user) {
        const { user, familyMembers, sharedFamilyMembers, userFavorites, userInfo, gifts } = response.data;
        updateUser({
          loggedIn: true,
          userId: user.id,
          user: {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            subscriptionTier: user.subscriptionTier,
            current_month_requests: user.current_month_requests,
          },
          familyMembers,
          sharedFamilyMembers,
          gifts,
          userFavorites,
          userInfo,
        });
        return true;
      } else {
        updateUser(getDefaultUserData());
        return false;
      }
    } catch (error) {
      console.error("fetching weeor",error);
      updateUser(getDefaultUserData());
      return false;
    }
  };

  const logout = async () => {
    try {
      // Clear the user data from localStorage
      localStorage.removeItem('userData');
      // Clear the user data from the context
      updateUser(getDefaultUserData());
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  return (
    <UserContext.Provider value={{ userData, updateUser, fetchUserData, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
